.flex-row-4 {
    align-items: flex-start;
    display: flex;
    margin-top: 1rem;
    min-width: 154px;
    max-height: 30px;
    align-items: center;
    gap: 0.5rem;
  }
  .flex-row-4 img {  
    height: 30px;
    width: 30px;
  }