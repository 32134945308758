@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700,600,800|Raleway:400,700,500,600,800|Lato:400,700,900,300,600|Montserrat:500");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

.screen a {
	display: contents;
	text-decoration: none;
}

.container-center-horizontal {
	display: flex;
	flex-direction: row;
	justify-content: center;
	pointer-events: none;
	width: 100%;
}

.container-center-horizontal > * {
	flex-shrink: 0;
	pointer-events: auto;
}

.no-decoration {
	display: contents;
	text-decoration: none;
}



.regular-gastromond{

	font-family: "gastromond", sans-serif;
font-weight: 400;
font-style: normal;

}