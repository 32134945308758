.rectngulo-81 {
	background-color: var(--white);
	border-radius: 28px;
	box-shadow: 0px 5px 10px #00000017;
	height: 55px !important;
	display: grid;
	grid-template-columns: max-content min-content min-content;
	align-items: center;
	padding: 1rem 2rem;
}
.rectngulo-81 span {
	width: 100%;
	margin: 0rem 1rem 0rem 0rem;
}
.trazado-arrow {
	height: 12px !important;
	object-fit: cover;
	width: 8px !important;
}

.rectngulo-81.vertical {
	transform: rotate(90deg);
}

@keyframes movearrow {
	from {
		transform: translateX(20px);
	}
    50% {
        transform: translateX(0px);   
    }
	to {
		transform: translateX(20px);
	}
}

img.trazado-arrow  {
	animation: movearrow;
	animation-duration: 2s;
	animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: both;
}
