.mobile-menu {
	--mobile-menu-width: 75vw;
	position: fixed;
	overflow: unset;
	background-color: white;
	width: var(--mobile-menu-width);
	height: 100vh;
	left: calc(var(--mobile-menu-width) * -1);
	top: 0;
	padding: 5%;
	transition: left 0.5s;
	max-width: 400px;
	z-index: 100000;
}
.mobile-menu.open {
	left: 0vw;
	transition: left 0.5s;
}
@media only screen and (min-width: 1000px) {
	.mobile-menu.open {
		left: calc(var(--mobile-menu-width) * -1);
		transition: left 0.5s;
	}
}
.mobile-menu-container {
	position: relative;
	z-index: 1000;
	height: 80vh;
	width: 100%;
	grid: min-content 1fr 1fr / 1fr;
	display: grid;
	align-content: space-around;
	align-items: flex-start;
	/* justify-content: space-around; */
}
.mobile-menu-belibox {
	position: relative;
}
.mobile-menu-container > div:last-of-type {
	align-self: flex-end;
}
.mobile-menu-belibox-links {
	display: grid;
	grid: 1fr / 1fr;
	gap: 1rem;
}
.mobile-menu-belibox-links-submenu {
	display: grid;
	grid: max-content / max-content min-content;
	width: 100%;
	align-items: center;
	gap: 1rem;
	position: relative;
}
.mobile-menu-belibox-links-submenu img.inverted {
	transform: rotate(180deg);
}
.suivez-nous {
	display: grid;
	grid: min-content / 1fr;
}
.mobile-menu-submenu {
	overflow: hidden;
	margin: 0rem 1.43rem;
	max-height: 0px;
	visibility: hidden;
	transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
.mobile-menu-submenu.subMenuOpen {
	max-height: 50vw;
	visibility: visible;
	transition: max-height 1s ease-in-out;
}
.mobile-menu-submenu li {
	margin: 0.75rem;
}
