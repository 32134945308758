@keyframes rotatetucan {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.tucan-loading img {
	animation: rotatetucan;
	animation-duration: 5s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-direction: normal;
	animation-fill-mode: both;
	width: 100%;
	height: 100%;
}
.tucan-loading img.big {
	max-width: 233px;
	max-height: 217px;
}
.tucan-loading img.small {
	max-width: 4rem;
	max-height: 4rem;
}
.tucan-loading img.x-small {
	max-width: 2rem;
	max-height: 2rem;
}
.tucan-loading img.xx-small {
	max-width: 1rem;
	max-height: 1rem;
}
