.global-header-menu {
	transition: background-color 0.25s;
	transition: top 0.25s;
	align-items: flex-start;
	display: flex;
	justify-content: space-around;
	height: 96px;
	width: 100%;
	position: absolute;
	top: 43px;
}

.rectngulo-84 {
	background-color: var(--pancho);
	border-radius: 25px;
	height: 50px;
	margin-left: 327px;
	opacity: 0.3;
	width: 50px;
}

.phone {
	letter-spacing: 0;
	line-height: 18px;
	/* margin-left: 16px; */
	margin-top: 16px;
	min-height: 26px;
	min-width: 123px;
	white-space: nowrap;
}

.unin-1 {
	height: 26px;
	margin-left: 77px;
	margin-top: 6px;
	object-fit: cover;
	width: 25px;
}

.menu {
	align-items: flex-start;
	display: flex;
	height: 100px;
	left: 116px;
	min-width: 1782px;
	position: absolute;
	top: 36px;
}

.grupo-178-1 {
	align-items: center;
	display: flex;
	/* margin-left: 327px; */
	/* margin-top: 4px; */
	min-width: 189px;
}
.icphoneintalk48px {
	height: 15px;
	object-fit: cover;
	width: 15px;
}

.phone-1 {
	margin-left: 0.75rem;
	white-space: nowrap;
}
.menu.menu-1 {
	flex: 1;
	height: unset;
	left: unset;
	position: relative;
	top: unset;
}
.beli-box {
	letter-spacing: 0;
	line-height: 17px;
	min-height: 25px;
	min-width: 85px;
	white-space: nowrap;
}

.faq {
	letter-spacing: 0;
	line-height: 17px;
	min-height: 25px;
	min-width: 37px;
	white-space: nowrap;
}

.agence-de-voyages {
	letter-spacing: 0;
	line-height: 17px;
	min-height: 25px;
	min-width: 182px;
	white-space: nowrap;
}

.grupo-177.grupo-177-1,
.grupo-177.grupo-177-2 {
	margin-top: 17px;
}
.grupo-179 {
	align-items: center;
	display: flex;
	/* margin-left: 38px; */
	min-width: 278px;
}

.overlap-group-1 {
	align-items: flex-end;
	background-color: var(--pancho-2);
	border-radius: 25px;
	display: flex;
	height: 50px;
	width: 50px;
	padding: 17.5px 15px;
}

.email-84 {
	height: 14px;
	object-fit: cover;
	width: 19px;
}

.text-8 {
	margin-left: 0.75rem;
}

.grupo-179.grupo-179-1,
.grupo-179.grupo-179-2 {
	margin-top: 4px;
}

.grupo-179.grupo-179-1 .overlap-group-1 {
	padding: 17.5px 15px;
}
.grupo-176 {
	display: flex;
	height: 96px;
	width: 96px;
}

.grupo-175 {
	align-items: flex-start;
	display: flex;
	flex: 1;
	min-width: 96.47px;
	padding: 0 0px;
}

.trazado-container {
	height: 96px;
	position: relative;
	width: 96px;
}

.trazado-28 {
	height: 7px;
	left: 17px;
	object-fit: cover;
	position: absolute;
	top: 76px;
	width: 7px;
}

.trazado-29 {
	height: 6px;
	left: 17px;
	object-fit: cover;
	position: absolute;
	top: 65px;
	width: 5px;
}

.trazado-30 {
	height: 8px;
	left: 38px;
	object-fit: cover;
	position: absolute;
	top: 71px;
	width: 10px;
}

.trazado-31 {
	height: 96px;
	left: 0;
	object-fit: cover;
	position: absolute;
	top: 0;
	width: 96px;
}

.grupo-176.grupo-176-1,
.grupo-176.grupo-176-2 {
	align-self: flex-end;
}

.grupo-176.grupo-176-1 .grupo-175,
.grupo-176.grupo-176-2 .grupo-175 {
	justify-content: center;
	padding: unset;
}
.fixed .text-8,
.fixed .phone-1 {
	color: var(--black-pearl);
}
.fixed .trazado-container {
	/* background-color: var(--well-read); */
	height: unset;
}
.fixed .grupo-177 {
	color: var(--well-read);
}
.fixed .circle {
	background-color: var(--black-pearl-2);
}
.fixed .grupo-175,
.fixed .profile-icon,
.fixed .grupo-177,
.fixed .contactHeaderMenu {
	align-self: center;
	align-items: center;
}
.fixed .grupo-177 {
	margin: 0;
}
.global-header-menu.fixed {
	background-color: rgba(255, 255, 255, 0.88);
	top: 0px;
	position: fixed;
	height: 96px;
	z-index: 999;
}
.fixed .selectedMenu {
	border-bottom: 2px solid var(--well-read);
}
.fixed .mobile-menu {
	top: 0;
}
@media only screen and (max-width: 1280px) {
	.global-header-menu.fixed {
		padding-top: 0rem;
		padding-bottom: 0rem;
		height: 120px;
	}
}
@media only screen and (max-width: 1000px) {
	.global-header-menu.fixed {
		height: 96px;
	}
}
@media only screen and (max-width: 450px) {
	.global-header-menu.fixed {
		padding: 0rem;
	}
}