.not-found-container {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 2.5fr 0.5fr 1fr 2fr;
	width: 100%;
	max-width: 1920px;
	min-height: 640px;
	justify-content: center;
	justify-items: center;
	text-align: center;
}
.not-found-container * {
	z-index: 10;
}
.not-found-text {
	align-self: flex-end;
	width: 100%;
	padding: 1rem;
}
.not-found-image {
	width: 100%;
	height: 100%;
	z-index: 0 !important;
	position: absolute;
	height: 1075px;
}
.not-found-title {
	color: var(--white);
	font-family: var(--font-family-open_sans);
	font-size: 49px;
	font-weight: 800;
	letter-spacing: 0;
	line-height: 49px;
}

.not-found-subtitle {
	color: var(--white);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-xl);
	font-weight: 500;
	letter-spacing: 0;
	line-height: 29px;
	margin-top: 1rem;
}
.not-found-aide {
	align-self: flex-end;
}
.aidez-le-retrouver-son-chemin {
	color: var(--white);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-l);
	font-weight: 500;
	letter-spacing: 0;
	line-height: 25px;
	white-space: nowrap;
}
.not-found-arrows {
	display: grid;
	gap: 3rem;
	width: 100%;
	grid-template-columns: min-content min-content;
	justify-content: center;
	align-self: flex-start;
	margin: 2rem 0;
}
.not-found-envolez {
	justify-self: flex-end;
}
.not-found-retour {
	justify-self: flex-start;
}
.not-found-tucan {
	align-self: flex-start;
	justify-self: flex-start;
	padding-left: 10%;
	width: 25%;
}
@media only screen and (max-width: 1280px) {
	.not-found-container {
		grid-template-rows: repeat(3, min-content) 1fr;
	}
	.not-found-title {
		/* font-size: 30px; */
		margin-top: 175px;
	}
}
@media only screen and (max-width: 1024px) {
	.not-found-tucan {
		justify-self: center;
		width: unset;
	}
}
@media only screen and (max-width: 600px) {
	.not-found-arrows {
		grid-template-columns: min-content;
		justify-items: center;
		justify-content: center;
		gap: 1.5rem;
	}
	.not-found-arrows span {
		font-size: var(--font-size-xxs) !important;
		font-weight: bolder;
	}
	.not-found-title {
		font-size: 30px;
		margin-top: 175px;
	}
	.not-found-title span {
		word-wrap: break-word;
		white-space: normal;
		word-break: normal;
		width: 100%;
		display: block;
		position: relative;
	}

	.not-found-retour,
	.not-found-envolez {
		justify-self: unset;
	}
}
