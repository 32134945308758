:root {
	--alto: #d9d9da;
	--black: #000000;
	--black-fade: #000000aa;
	--black-haze: #f6f6f6;
	--black-pearl: #04151f;
	--black-pearl-2: #04151fbd;
	--black-pearl-3: #04151f8c;
	--black-overlay: rgba(4, 21, 31, 0.5);
	--bright-gray: #35424a;
	--cararra: #f0eeea;
	--coconut: #fefeff;
	--dawn-pink: #f8e8e7;
	--dove-gray: #707070;
	--eerie-black: #1a1a1b;
	--gallery: #ededed;
	--hunter-green: #161615;
	--monza: #c10416;
	--monza-2: #c20218;
	--pancho: #efd6ac;
	--pancho-2: #efd6ac4c;
	--pancho-3: #efd6ac4d;
	--stack: #8d8d8d;
	--well-read: #be342b;
	--white: #ffffff;
	--white-2: #ffffff08;
	--green-ok: #28a745;
	--red-error: #dc3545;
	--font-size-xxs: 13px;
	--font-size-xs: 15px;
	--font-size-s: 16px;	
	--font-size-m: 18px;
	--font-size-l: 20px;
	--font-size-xl: 23px;
	--font-size-xxl: 26px;
	--font-size-xxxl: 48px;
	--font-size-xxl: 25px;
	--font-family-lato: "Lato", Helvetica;
	--font-family-montserrat: "Montserrat", Helvetica;
	--font-family-open_sans: "Open Sans", Helvetica;
	--font-family-raleway: "Raleway", Helvetica;
	--font-family-poppins: "Poppins", Helvetica;
}
.lato-light-black-pearl-13px {
	color: var(--black-pearl);
	font-family: var(--font-family-lato);
	font-size: 13px;
	font-style: normal;
	font-weight: 300;
}
.lato-light-hunter-green-23px {
	color: var(--hunter-green);
	font-family: var(--font-family-lato);
	font-size: var(--font-size-xl);
	font-style: normal;
	font-weight: 300;
}
.lato-light-hunter-green-25px {
	color: var(--hunter-green);
	font-family: var(--font-family-lato);
	font-size: var(--font-size-xxl);
	font-style: normal;
	font-weight: 300;
}
.raleway-normal-black-pearl-18px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-m);
	font-style: normal;
	font-weight: 400;
}
.opensans-extra-bold-dove-gray-17px {
	color: var(--dove-gray);
	font-family: var(--font-family-open_sans);
	font-size: 17px;
	font-style: normal;
	font-weight: 800;
}
.opensans-extra-bold-black-pearl-17px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: 17px;
	font-style: normal;
	font-weight: 800;
}
.opensans-extra-bold-black-pearl-20px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-l);
	font-style: normal;
	font-weight: 800;
}
.opensans-extra-bold-black-pearl-22px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: 22px;
	font-style: normal;
	font-weight: 800;
}
.opensans-extra-bold-black-pearl-25px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-xl);
	font-style: normal;
	font-weight: 800;
}
.opensans-extra-bold-black-pearl-38px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: 38px;
	font-style: normal;
	font-weight: 800;
}
.opensans-extra-bold-black-pearl-32px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-xl);
	font-style: normal;
	font-weight: 800;
}
.opensans-extra-bold-black-pearl-42px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: 42px;
	font-style: normal;
	font-weight: 800;
}
.opensans-extra-bold-well-read-42px {
	color: var(--well-read);
	font-family: var(--font-family-open_sans);
	font-size: 42px;
	font-style: normal;
	font-weight: 800;
}
.opensans-extra-bold-well-read-31px {
	color: var(--well-read);
	font-family: var(--font-family-open_sans);
	font-size: 31px;
	font-style: normal;
	font-weight: 800;
}
.opensans-extra-bold-well-read-36px {
	color: var(--well-read);
	font-family: var(--font-family-open_sans);
	font-size: 36px;
	font-style: normal;
	font-weight: 800;
}
.opensans-normal-black-pearl-20px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-l);
	font-style: normal;
	font-weight: 400;
}
.opensans-normal-black-pearl-25px {
	color: var(--black);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-xl);
	font-style: normal;
	font-weight: 400;
}
.opensans-normal-black-pearl-32px {
	color: var(--black);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-xl);
	font-style: normal;
	font-weight: 400;
}
.opensans-normal-black-pearl-3-25px {
	color: var(--black-pearl-3);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-xl);
	font-style: normal;
	font-weight: 400;
}
.opensans-normal-well-read-25px {
	color: var(--well-read);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-xl);
	font-style: normal;
	font-weight: 400;
}

.opensans-extra-bold-25px {
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-xxl);
	font-style: normal;
	font-weight: 800;
}

.opensans-normal-black-pearl-16px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-s);
	font-style: normal;
	font-weight: 400;
}
.opensans-normal-black-pearl-23px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: 23px;
	font-style: normal;
	font-weight: 400;
}

.opensans-extra-bold-white-17px {
	color: var(--white);
	font-family: var(--font-family-open_sans);
	font-size: 17px;
	font-style: normal;
	font-weight: 800;
}
.opensans-extra-bold-white-10px {
	color: var(--white);
	font-family: var(--font-family-open_sans);
	font-size: 10px;
	font-style: normal;
	font-weight: 800;
}

.opensans-bold-white-13px a,
.opensans-bold-white-13px {
	color: var(--white);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-xxs);
	font-style: normal;
	font-weight: 700;
}

.raleway-semi-bold-eerie-black-26px {
	color: var(--eerie-black);
	font-family: var(--font-family-raleway);
	font-size: 26px;
	font-style: normal;
	font-weight: 600;
}

.opensans-bold-white-18px {
	color: var(--white);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-m);
	font-style: normal;
	font-weight: 700;
}
.opensans-bold-well-read-16px {
	color: var(--well-read);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-s);
	font-style: normal;
	font-weight: 700;
}
.opensans-bold-well-read-18px {
	color: var(--well-read);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-m);
	font-style: normal;
	font-weight: 700;
}
.raleway-normal-black-pearl-16px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-s);
	font-style: normal;
	font-weight: 400;
}

.opensans-extra-bold-white-25px {
	color: var(--white);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-xxl);
	font-style: normal;
	font-weight: 800;
}
.raleway-regular-white-13px {
	color: var(--white);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-xxs);
	font-style: normal;
	font-weight: 400;
}
.raleway-medium-white-16px {
	color: var(--white);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-s);
	font-style: normal;
	font-weight: 500;
}
.raleway-medium-white-22px {
	color: var(--white);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-xl);
	font-style: normal;
	font-weight: 500;
}
.raleway-normal-black-pearl-18px-2 {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-m);
}

.raleway-medium-stack-16px,
.raleway-medium-stack-16px-2 {
	color: var(--stack);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-s);
	font-style: normal;
	font-weight: 500;
}
.raleway-medium-stack-18px {
	color: var(--stack);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-m);
	font-style: normal;
	font-weight: 500;
}
.raleway-medium-well-read-32px {
	color: var(--well-read);
	font-family: var(--font-family-raleway);
	font-size: 2rem;
	font-style: normal;
	font-weight: 500;
}

.raleway-medium-well-read-43px {
	color: var(--well-read);
	font-family: var(--font-family-raleway);
	font-size: 43px;
	font-style: normal;
	font-weight: 500;
}

.raleway-normal-black-pearl-20px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-l);
	font-style: normal;
	font-weight: 400;
}
.raleway-normal-black-pearl-23px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: 23px;
	font-style: normal;
	font-weight: 400;
}
.raleway-normal-black-pearl-32px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: 32px;
	font-style: normal;
	font-weight: 400;
}
.opensans-extra-bold-black-pearl-25px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-xxl);
	font-style: normal;
	font-weight: 800;
}

.opensans-extra-bold-well-read-25px {
	color: var(--well-read);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-xxl);
	font-style: normal;
	font-weight: 800;
}
.opensans-extra-bold-well-read-60px {
	color: var(--well-read);
	font-family: var(--font-family-open_sans);
	font-size: 60px;
	font-style: normal;
	font-weight: 800;
}
.opensans-bold-dove-gray-16px {
	color: var(--dove-gray);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-s);
	font-style: normal;
	font-weight: 700;
}
.opensans-bold-white-15px {
	color: var(--white);
	font-family: var(--font-family-open_sans);
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
}

.opensans-bold-white-16px a,
.opensans-bold-white-16px {
	color: var(--white);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-s);
	font-style: normal;
	font-weight: 700;
}
.opensans-bold-black-pearl-14px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
}
.opensans-bold-black-pearl-16px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
}

.opensans-bold-black-pearl-17px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
}
.opensans-bold-black-pearl-20px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-s);
	font-style: normal;
	font-weight: 700;
}
.opensans-bold-black-pearl-23px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: 23px;
	font-style: normal;
	font-weight: 700;
}
.opensans-bold-black-pearl-25px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: 25px;
	font-style: normal;
	font-weight: 700;
}
.opensans-bold-black-pearl-38px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: 38px;
	font-style: normal;
	font-weight: 700;
}
.raleway-normal-black-pearl-15px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-xs);
	font-style: normal;
	font-weight: 400;
}
.raleway-normal-black-pearl-13px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-xxs);
	font-style: normal;
	font-weight: 400;
}
.raleway-normal-black-pearl-10px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-xxs);
	font-style: normal;
	font-weight: 400;
}

.raleway-bold-black-pearl-15px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-xs);
	font-style: normal;
	font-weight: 700;
}
.raleway-bold-black-pearl-16px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
}
.raleway-bold-black-pearl-18px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-m);
	font-style: normal;
	font-weight: 700;
}
.raleway-bold-black-pearl-20px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-l);
	font-style: normal;
	font-weight: 700;
}
.raleway-bold-black-pearl-23px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: 23px;
	font-style: normal;
	font-weight: 700;
}
.raleway-bold-black-pearl-25px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: 25px;
	font-style: normal;
	font-weight: 700;
}
.raleway-bold-well-read-25px {
	color: var(--well-read);
	font-family: var(--font-family-raleway);
	font-size: 25px;
	font-style: normal;
	font-weight: 700;
}
.raleway-bold-black-pearl-42px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: 42px;
	font-style: normal;
	font-weight: 700;
}
.raleway-bold-black-pearl-32px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
}
.raleway-bold-white-20px {
	color: var(--white);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-l);
	font-style: normal;
	font-weight: 700;
}
.raleway-semi-bold-coconut-42px {
	color: var(--coconut);
	font-family: var(--font-family-raleway);
	font-size: 42px;
	font-style: normal;
	font-weight: 600;
}

.montserrat-medium-coconut-18px {
	color: var(--coconut);
	font-family: var(--font-family-montserrat);
	font-size: var(--font-size-m);
	font-style: normal;
	font-weight: 500;
}
.montserrat-normal-black-pearl-16px {
	color: var(--black-pearl);
	font-family: var(--font-family-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
}
.montserrat-normal-black-pearl-20px {
	color: var(--black-pearl);
	font-family: var(--font-family-montserrat);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
}
.opensans-normal-white-10px {
	color: var(--white);
	font-family: var(--font-family-open_sans);
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
}
.montserrat-medium-black-pearl-22px {
	color: var(--black-pearl);
	font-family: var(--font-family-montserrat);
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
}

.opensans-normal-white-16px {
	color: var(--white);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-s);
	font-style: normal;
	font-weight: 400;
}
.opensans-normal-dove-gray-16px {
	color: var(--dove-gray);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-s);
	font-style: normal;
	font-weight: 400;
}
.opensans-normal-dove-gray-13px {
	color: var(--dove-gray);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-xxs);
	font-style: normal;
	font-weight: 400;
}
.raleway-medium-black-pearl-14px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-xs);
	font-style: normal;
	font-weight: 500;
}
.raleway-medium-black-pearl-16px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-s);
	font-style: normal;
	font-weight: 500;
}
.raleway-medium-black-pearl-18px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
}
.raleway-medium-black-pearl-20px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
}
.raleway-medium-black-pearl-21px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-l);
	font-style: normal;
	font-weight: 500;
}
.raleway-medium-black-pearl-24px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
}
.raleway-medium-well-read-25px {
	color: var(--well-read);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-xxl);
	font-style: normal;
	font-weight: 500;
}

.raleway-extra-bold-white-70px {
	color: var(--white);
	font-family: var(--font-family-raleway);
	font-size: 70px;
	font-style: normal;
	font-weight: 800;
}

.raleway-extra-bold-well-read-20px {
	color: var(--well-read);
	font-family: var(--font-family-raleway);
	font-size: 20px;
	font-style: normal;
	font-weight: 800;
}
.raleway-medium-bold-white-70px {
	color: var(--white);
	font-family: var(--font-family-raleway);
	font-size: 70px;
	font-style: normal;
	font-weight: 800;
}
.raleway-semi-bold-white-26px {
	color: var(--white);
	font-family: var(--font-family-raleway);
	font-size: 26px;
	font-style: normal;
	font-weight: 600;
}

.opensans-extra-bold-well-read-17px {
	color: var(--well-read);
	font-family: var(--font-family-open_sans);
	font-size: 17px;
	font-style: normal;
	font-weight: 800;
}
.opensans-extra-bold-well-read-20px {
	color: var(--well-read);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-l);
	font-style: normal;
	font-weight: 800;
}

.opensans-extra-bold-white-31px {
	color: var(--white);
	font-family: var(--font-family-open_sans);
	font-size: 31px;
	font-style: normal;
	font-weight: 800;
}
.opensans-extra-bold-white-38px {
	color: var(--white);
	font-family: var(--font-family-open_sans);
	font-size: 38px;
	font-style: normal;
	font-weight: 800;
}
.opensans-extra-bold-white-49px {
	color: var(--white);
	font-family: var(--font-family-open_sans);
	font-size: 49px;
	font-style: normal;
	font-weight: 800;
}
.opensans-semi-bold-white-20px {
	color: var(--white);
	font-family: var(--font-family-open_sans);
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
}
.opensans-semi-bold-white-22px {
	color: var(--white);
	font-family: var(--font-family-open_sans);
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
}

.opensans-extra-bold-well-read-25px-2 {
	color: var(--well-read);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-xxl);
}

.opensans-extra-bold-well-read-28px {
	color: var(--well-read);
	font-family: var(--font-family-open_sans);
	font-size: 28px;
	font-style: normal;
	font-weight: 800;
}

.lato-semi-bold-black-pearl-18px {
	color: var(--black-pearl);
	font-family: var(--font-family-lato);
	font-size: var(--font-size-m);
	font-style: normal;
	font-weight: 600;
}

.lato-normal-black-pearl-18px {
	color: var(--black-pearl);
	font-family: var(--font-family-lato);
	font-size: var(--font-size-m);
	font-style: normal;
	font-weight: 400;
}
.lato-normal-white-25px {
	color: var(--white);
	font-family: var(--font-family-lato);
	font-size: 25px;
	font-style: normal;
	font-weight: 400;
}
.raleway-semi-bold-black-pearl-15px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-xs);
	font-style: normal;
	font-weight: 600;
}
.raleway-semi-bold-black-pearl-18px {
	color: var(--black-pearl);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-m);
	font-style: normal;
	font-weight: 600;
}
.opensans-semi-bold-black-pearl-16px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
}
.opensans-semi-bold-black-pearl-18px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-m);
	font-style: normal;
	font-weight: 600;
}

.opensans-semi-bold-black-pearl-20px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-l);
	font-style: normal;
	font-weight: 600;
}
.opensans-semi-bold-black-pearl-36px {
	color: var(--black-pearl);
	font-family: var(--font-family-open_sans);
	font-size: 36px;
	font-style: normal;
	font-weight: 600;
}
.opensans-semi-bold-well-read-20px {
	color: var(--well-read);
	font-family: var(--font-family-open_sans);
	font-size: var(--font-size-l);
	font-style: normal;
	font-weight: 600;
}
.opensans-semi-bold-well-read-36px {
	color: var(--well-read);
	font-family: var(--font-family-open_sans);
	font-size: 36px;
	font-style: normal;
	font-weight: 600;
}
.raleway-semi-bold-well-read-16px {
	color: var(--well-read);
	font-family: var(--font-family-raleway);
	font-size: var(--font-size-s);
	font-style: normal;
	font-weight: 600;
}

.border-1px-alto {
	border: 1px solid var(--alto);
}

.border-1px-black-pearl {
	border: 1px solid var(--black-pearl);
}

.border-2px-white {
	border: 2px solid var(--white);
}

.border-2px-dove-gray {
	border: 2px solid var(--dove-gray);
}

.border-1px-black-haze {
	border: 1px solid var(--black-haze);
}
.poppins-bold-black-pearl-16px {
	color: var(--black-pearl);
	font-family: var(--font-family-poppins);
	font-size: var(--font-size-s);
	font-style: normal;
	font-weight: 600;
}
.poppins-bold-black-pearl-27px {
	color: var(--black-pearl);
	font-family: var(--font-family-poppins);
	font-size: 27px;
	font-style: normal;
	font-weight: 600;
}
.poppins-semi-bold-black-26px {
	color: var(--black);
	font-family: var(--font-family-poppins);
	font-size: 26px;
	font-style: normal;
	font-weight: 600;
}
.poppins-semi-bold-black-16px {
	color: var(--black-fade);
	font-family: var(--font-family-poppins);
	font-size: var(--font-size-s);
	font-style: normal;
	font-weight: 600;
}
.align-flex-end {
	align-self: flex-end !important;
}
.justify-flex-end {
	justify-self: flex-end !important;
}
.justify-flex-start {
	justify-self: flex-start !important;
}
.justify-center {
	justify-self: center !important;
}
.m-1 {
	margin: 1rem;
}
.m-0 {
	margin: 0 !important;
}
.mb-1 {
	margin-bottom: 1rem;
}
.mb-2 {
	margin-bottom: 2rem;
}
.mb-3 {
	margin-bottom: 3rem;
}
.mb-4 {
	margin-bottom: 4rem;
}
.mb-5 {
	margin-bottom: 5rem;
}
.mt-1 {
	margin-top: 1rem;
}
.mt-2 {
	margin-top: 2rem;
}
.ml-1 {
	margin-left: 1rem;
}
.ml-2 {
	margin-left: 2rem;
}
.ml-3 {
	margin-left: 3rem;
}
.ub-black-1 {
	border-bottom: 1px solid var(--black);
}
.ub-dove-gray-1 {
	border-bottom: 1px solid var(--dove-gray);
}
.ub-carrara-1 {
	border-bottom: 1px solid var(--cararra);
}
.ub-alto-1 {
	border-bottom: 1px solid var(--alto);
}
.parallax {
	background-attachment: scroll !important;
	background-position: center top !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
}
.well-read {
	color: var(--well-read);
}
