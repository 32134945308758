.index {
	align-items: flex-start;
	background-color: var(--white);
	display: flex;
	/* height: 4597px; */
	overflow: hidden;
	position: relative;
	width: 100%;
	max-width: 1920px;
}
.index-1 {
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: auto;
	margin-bottom: 3rem;
	max-width: 1920px;
}
.loading-screen-suspense {
	display: grid;
	justify-content: center;
	margin-top: 15%;
}
.input-error,
.input-error input {
	border: 1px solid var(--red-error);
	outline: 1px solid var(--red-error);
}
.input-error::placeholder {
	color: var(--red-error);
}
.input-ok,
.input-ok input {
	border: 1px solid var(--green-ok);
	outline: 1px solid var(--green-ok);
}
.link-no-decoration {
	text-decoration: none;
	color: unset;
}

.form input[type="number"],
.form input[type="text"],
.form .input,
.form textarea {
	background: white;
	text-align: left;
	font-family: var(--font-family-raleway);
	font-size: 16px;
	border-radius: 15px;
	width: 100%;
	padding: 1rem;
	border: 0;
	filter: drop-shadow(30px 10px 60px rgba(0, 0, 0, 0.161));
}
.form input[type="number"],
.form input[type="text"],
.form .input {
	min-height: 70px;
	align-content: center;
	align-items: center;
	display: inline;
}
.form *::placeholder {
	color: rgba(4, 21, 31, 1);
}
@media only screen and (max-width: 1280px) {
	.form input[type="number"],
	.form input[type="text"],
	.form .input {
		min-height: 60px;
	}
}
@media only screen and (max-width: 800px) {
	.form input[type="number"],
	.form input[type="text"],
	.form .input {
		min-height: 50px;
	}
}
.form-small input[type="number"],
.form-small input[type="text"],
.form-small .input,
.form-small textarea {
	background: white;
	text-align: left;
	font-family: var(--font-family-raleway);
	font-size: 16px;
	border-radius: 15px;
	width: 100%;
	padding: 1rem;
	border: 0;
	filter: drop-shadow(30px 10px 60px rgba(0, 0, 0, 0.161));
}
.form-small input[type="number"],
.form-small input[type="text"],
.form-small .input {
	height: 36px;
	align-content: center;
	align-items: center;
	display: inline;
}
.form-small *::placeholder {
	color: rgba(4, 21, 31, 1);
}

.lowerCase {
	text-transform: lowercase;
}
.upperCase {
	text-transform: uppercase;
}
.underline {
	text-decoration: underline;
}
.white-font {
	color: var(--white);
}
.bold {
	font-weight: bold;
}
.black,
.black-font {
	color: var(--black-pearl);
}
.nowrap {
	white-space: nowrap;
}
.faq-link {
	color: #be342b;
}
.entire-row {
	grid-row: 1 / -1;
}
.capitalize {
	text-transform: capitalize;
}
@media only screen and (min-width: 1920px) {
	html {
		zoom: 2;
	}
}
@media only screen and (max-width: 1920px) {
	html {
		zoom: 1;
	}
}
.regular {
	align-items: center;
}
.big {
	align-items: center;
	padding: 0.93rem 3.56rem;
}
.bigger {
	align-items: center;
	padding: 1.4rem 5.34rem;
}
.rounded-input {
	border: 0px !important;
	border-radius: 10px;
	box-shadow: 0px 5px 15px #00000029;
}
.fullRow {
	grid-column: 1 / -1;
}
.closeButton {
	position: absolute;
	top: 0;
	right: 0;
	padding: 1rem;
	cursor: pointer;
}
.Toastify {
	z-index: 2147483645;
	position: absolute;
}
