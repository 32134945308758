.pointer {
	cursor: pointer;
}
.global-header-menu a {
	text-decoration: none;
	color: inherit;
}
.selectedMenu {
	border-bottom: 2px solid white;
}
.contactHeaderMenu {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: auto;
	gap: 1rem;
}
.contactHeaderMenu > div {
	margin-right: 1rem;
}
.grupo-177 {
	display: grid;
	grid: 1fr / repeat(3, min-content);
	justify-content: space-between;
	justify-items: flex-start;
	gap: 3%;
	width: 100%;
	max-width: 500px;
	align-content: center;
	align-items: center;
}
.grupo-178-1 {
	min-width: unset;
	width: 100%;
}
.grupo-179 {
	width: 100%;
	min-width: unset;
}
.global-header-menu {
	display: grid;
	grid-template-columns: min-content min-content 5fr min-content;
	gap: 4%;
	padding: 3rem 3%;
	justify-content: space-between;
	justify-items: center;
	align-content: center;
	align-items: center;
	width: 100%;
	max-width: 1920px;
	z-index: 100;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	position: absolute;
}
.profile-icon {
	justify-self: start;
}
.contact-header-hamburguer {
	display: none;
}
.circle {
	/* background: red; */
	border-radius: 25px;
	width: 50px;
	height: 50px;
}
.profile-icon-mobile {
	display: none;
}
@media only screen and (max-width: 1280px) {
	.global-header-menu {
		align-items: center;
		gap: 5rem;
		padding: 0 2.5rem;
	}
	.contactHeaderMenu > div {
		margin-bottom: 1rem;
	}
	.grupo-177 {
		justify-content: space-between;
	}
	.contactHeaderMenu {
		grid-template-columns: 1fr;
		height: 100%;
	}
}
@media only screen and (max-width: 1000px) {
	.profile-icon {
		display: none;
	}
	.profile-icon-mobile {
		display: block;
	}
	.contact-header-hamburguer {
		display: block;
	}
	/* .global-header-menu * {
		font-size: 0.95rem;
	} */
	.global-header-menu {
		justify-items: flex-end;
		align-items: center;
		gap: 2.5rem;
		padding: 0 1rem;
		grid: 1fr / min-content 1fr;
	}
	.grupo-177,
	.text-8,
	.phone-1 {
		display: none;
	}
	.contactHeaderMenu {
		grid: 1fr / repeat(4, min-content);
		align-content: flex-start;
		align-items: flex-start;
		justify-items: flex-end;
		justify-content: flex-end;
		gap: 1rem;
		width: 100%;
		/* height: 32px; */
	}
	.contactHeaderMenu > div {
		margin: 0rem;
	}
}
@media only screen and (max-width: 450px) {
	.global-header-menu * {
		font-size: 0.85rem;
	}
	.global-header-menu {
		gap: 0rem;
		padding: 0rem 0.75rem;
	}
	.contactHeaderMenu {
		justify-content: flex-end;
		gap: 0.5rem;
	}
	.contactHeaderMenu > div {
		margin: 0rem;
	}
	.grupo-177 {
		align-items: flex-start;
	}
	.grupo-178-1 {
		justify-content: flex-end;
		align-items: flex-start;
		width: auto;
	}
	.text-8,
	.phone-1 {
		margin-left: 0.2rem;
		min-width: unset;
		width: 100%;
	}
	.circle,
	.circle img {
		width: 35px;
		height: 35px;
	}
}
